<script setup>

import {computed, onMounted, ref} from 'vue';
import { useAuthActions } from '@auth';
import { useProfileResources } from '@profile';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useRouter } from 'vue-router';
import Notifications from '@/Containers/Notifications/Notifications';

const isShow = ref(false);
const router = useRouter();
const { signOut } = useAuthActions();
const languages = ref(
    process.env.VUE_APP_AVAILABLE_LOCALES.split('|')
);

const profile = useProfileResources('Profile');

onMounted(() => {
    const activeTheme = STATE.IS_LIGHT_MODE ? 'light' : 'dark';
    document.documentElement.setAttribute('theme', activeTheme);
});

const logout = () => {
    signOut().then(() => router.push({ name: 'SignIn' }));
};

const onChangeTheme =  (checked) => {
    const activeTheme = checked ? 'light' : 'dark';
    STATE.IS_LIGHT_MODE = checked;
    localStorage.setItem('theme', activeTheme);
    document.documentElement.setAttribute('theme', activeTheme);
};

const scrollToNewsfeed = () => {
    const element = document.querySelector('.newsfeed');
    if (!element) return;

    const top =
        element.getBoundingClientRect().top + window.scrollY - document.documentElement.clientTop;
    
    window.scrollTo({
      top,
      behavior: "smooth"
    });
}

const logo = computed(() => {
    let template = '';
    switch (true) {
        default:
            template = `<img src="${require('@assets/img/logo.svg')}" alt="LOGO"/>`;
            break;
    }

    return template;
});
</script>

<template>
    <div class="topbar">
        <router-link class="logo" v-bind:to="{ name: 'Trading', params: $route.params }">
            <div v-html="logo"/>
        </router-link>

        <div class="toolbar" v-bind:class="{show: isShow}">

            <label class="news-link" v-on:click="scrollToNewsfeed">{{ $t('parts.trading_news', 'Trading News') }}</label>

            <g-fade-container icon="wallet" v-bind:label="$t('parts.finances', 'Finances')">
                <div class="finance-menu">
                    <router-link class="menu-item" v-bind:to="{ name: 'Wallet', params: $route.params }">{{ $t('finances.deposit') }}</router-link>
                    <label class="menu-item" v-on:click="PopUp.open(require('@withdraw/components/PopUps/WithdrawForm')).setCaption($t('wallet.withdrawal', 'Withdrawal'))">{{ $t('wallet.withdrawal', 'Withdrawal') }}</label>
                    <router-link class="menu-item" v-bind:to="{ name: 'History', params: $route.params }">{{ $t('parts.history') }}</router-link>
                </div>
            </g-fade-container>

            <g-fade-container icon="profile" v-bind:label="`${profile.first_name} ${profile.last_name}`">
                <div class="profile-menu">
                    <router-link v-bind:to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                                 class="menu-item"
                                 v-bind:class="{ active: $route.name === 'Profile' }">
                        <!--<g-symbol name="profile" width="24" height="24"/>-->
                        {{ $t('parts.profile', 'Profile') }}
                    </router-link>
                    <label class="menu-item" v-on:click="logout">
                        <!--<g-symbol name="logout" width="24" height="24"/>-->
                        {{ $t('base.logout', 'Logout') }}
                    </label>
                </div>
            </g-fade-container>

            <g-lang-select v-bind:lang-list="languages"/>

            <notifications />
        </div>

        <div class="buttons">
            <router-link
                v-if="$route.name !== 'Trading'"
                v-bind:to="{ name: 'Trading', params: $route.params }"
                class="trading-btn"
            >
                <span class="bg" />
                <span>{{ $t('finances.start_trading', 'Start Trading') }}</span>
            </router-link>
            <router-link
                v-bind:to="{ name: 'Wallet', params: $route.params }"
                class="deposit-btn"
            >
                <span class="bg" />
                <span>
                    <g-symbol name="deposit" width="16" height="17" />
                    {{ $t('finances.deposit', 'Deposit') }}
                </span>
            </router-link>
            <!--
            <router-link v-bind:to="{ name: 'Wallet', params: $route.params }" class="deposit-btn">
                <span class="bg" />
                <span>
                    <g-symbol name="deposit" width="16" height="17" />
                    {{ $t('finances.deposit', 'Deposit') }}
                </span>
            </router-link>
            -->
        </div>

    </div>
</template>

<style lang="scss" scoped>
.topbar {
    padding: 0 10px;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    max-width: var(--env-width, $env-width);
    position: relative;

    & > .g-flex {
        flex-grow: 1;
    }
}

.logo {
    font-size: 20px;
    font-weight: bold;

    & > div {
        gap: 8px;
        display: flex;
        align-items: center;

        &:deep(img) {
            height: 60px;
        }

        &:deep(span):first-child {
            font-weight: bold;
            color: var(--warning, $warning);
        }

        &:deep(span):last-child {
            margin-left: -6px;
            font-weight: normal;
            color: var(--title-text-color, $title-text-color);
        }
    }
}

.toolbar {
    z-index: 4;
    gap: 8px;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 40px;
    border-radius: var(--bar-rounding, $bar-rounding);
    background-color: var(--topbar-bg, $topbar-bg);
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
    width: 100%;
}

.trading-btn,
.deposit-btn {
    > span {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
    }

    > .bg {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        border-radius: 16px;
        overflow: hidden;
        transition: transform 300ms;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            background-color: #cccccc1a;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 400ms;
        }

        &::after {
            transition-delay: 150ms;
        }
    }

    &:hover {
        > .bg {
            transform: scale(1.05);

            &::before,
            &::after {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
}

.trading-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 13px 30px;
    gap: 6px;
    isolation: isolate;
    color: #fff;

    > .bg {
        background: radial-gradient(56.13% 68.75% at 50% 97.71%, #617e8994 0%, #7898a200 100%), #ffffff1a;
        border: 2px solid #ffffff0d;

        &::before,
        &::after {
            background-color: #7be3ff1a;
        }
    }
}

.deposit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 13px 30px;
    color: #fff;

    > .bg {
        background: radial-gradient(58.69% 71.87% at 50% 97.71%, #7be3ff94 0%, #33c5ed00 100%), #2867c3;
        border: 2px solid #ffffff05;

        &::before,
        &::after {
            background-color: #7be3ff1a;
        }
    }

    > span {
        gap: 6px;
    }
}

.g-fade-container {
    max-width: 200px;

    &:deep(.label) {
        padding: 0 !important;
    }

    &:deep(.container) {
        z-index: 20;
    }

    .finance-menu,
    .profile-menu {
        padding: 10px 5px;
        overflow: hidden;
        background-color: var(--bar-bg, $bar-bg);
        //border: 1px solid var(--separator-color, $separator-color);

        & > .menu-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 10px;
            margin: 5px 0;
            cursor: pointer;
            border-radius: 5px;
            color: var(--title-text-color, $title-text-color);
            fill: var(--title-text-color, $title-text-color);

            &:hover {
                //color: var(--title-text-color, $title-text-color);
                //fill: var(--title-text-color, $title-text-color);
                background-color: var(--primary-hover, $primary-hover);
            }

            &.disabled {
                pointer-events: none;
                color: var(--disabled-color, $disabled-color);
                fill: var(--disabled-color, $disabled-color);
            }

            &.theme-switcher {
                justify-content: space-between;
            }

            .g-switch {
                &:deep(input) + span {
                    background-color: var(--primary-hover, $primary-hover) !important;
                }
            }

            .menu-item {
                &.disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
        }

        & > .separator {
            height: 1px;
            border: none;
            margin: 10px 0;
            background-color: var(--separator-color, $separator-color);
        }
    }
}

@media (min-width: 1365px) {
    .topbar {
        justify-content: center;
    }

    .logo {
        top: 50%;
        left: 20px;
        position: absolute;
        transform: translateY(-50%);
    }

    .buttons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
}

@media (max-width: 640px) {
    .news-link {
        display: none;
    }

    .toolbar {
        padding: 0 10px;
    }
}

@media (min-width: 1100px) {
    .buttons {
        width: auto;
    }
}

@media (min-width: 525px) {
    .g-hamburger {
        display: none;
    }

    .g-fade-container {
        &:deep(.icon:not(.icon-bell)) {
            display: none;
        }
    }

    @media (max-width: 1364px) {
        .topbar {
            justify-content: space-between;
        }
    }
}

@media (max-width: 524px) {
    .topbar {
        justify-content: space-between;
    }

    .g-fade-container {
        max-width: 200px;
        margin: 5px 0 10px 0;

        &:deep(.value) {
            display: none;
        }

        &.active:deep(.icon) {
            fill: white;
            background-color: var(--primary, $primary);
        }
    }
}
</style>
