<script setup>

import { onMounted, onUnmounted, ref, computed } from 'vue';
import { WebSockets as Stream } from '@/Ship';
import { usePositionActions } from '@position';
import { useProfileActions } from '@profile';

const getHistoryResource = usePositionActions('getHistoryResource');
const history = getHistoryResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const page = ref(1);
let perPage = 10;

const positions = computed(() => {
	const startIndex = perPage * (page.value - 1);
	const endIndex = startIndex + perPage;

	// eslint-disable-next-line vue/no-side-effects-in-computed-properties
	return history.state.sort((a, b) => b.created_at > a.created_at ? 1 : -1).slice(startIndex, endIndex);
});

const totalPnL = computed(() => {
    return history.state.reduce((reduce, position) => Number(position.pl) + reduce, 0);
});


/** Stream **/
const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

onMounted(() => {
    stream.subscribe({ trading: { user_id: profile.id, tab: 'history' }});
    stream.addHandlers({
        //history: resolveHistoryStream,
        history: function (stream) {
            stream.forEach(position => {
                const idx = history.state.findIndex(item => item.id === position.id);
                if (idx > -1) {
                    Object.assign(history.state[idx], position);
                } else {
                    history.state.unshift(position);
                }
            });
        },
    });
});

onUnmounted(() => {
    stream.unsubscribe('tradings');
});

</script>

<template>
    <div class="history">
        <g-flex class="total_pl" align="center" justify="end">
            {{ $t('finances.total_pl', 'Total PnL') }}: <g-symbol name="tether" width="18" height="18"/><span class="pl" v-bind:class="totalPnL > 0 ? 'increase' : 'decrease'">{{ Number(totalPnL.toFixed(2)) }}</span>
        </g-flex>

        <div class="titles row">
            <span class="cell">{{ $t('trading.currency_pair', 'Currency pair') }}</span>
            <span class="cell">{{ $t('trading.side', 'Side') }}</span>
            <span class="cell">{{ $t('trading.value', 'Value') }}</span>
            <span class="cell">{{ $t('trading.quantity', 'Quantity') }}</span>
            <span class="cell">{{ $t('trading.entry_price', 'Entry price') }}</span>
            <span class="cell">{{ $t('trading.close_price', 'Close price') }}</span>
            <span class="cell">{{ $t('trading.pl', 'PnL') }}</span>
            <span class="cell">Swap</span>
            <span class="cell">{{ $t('trading.status', 'Status') }}</span>
            <span class="cell">{{ $t('trading.opened_at', 'Opened at') }}</span>
            <span class="cell">{{ $t('trading.closed_at', 'Closed at') }}</span>
        </div>

        <div class="positions">
            <div class="row"
                 v-for="record in positions"
                 v-bind:key="`hisrory-${record.id}`">

                <span class="currency-pair cell">
                    <g-flex gap="8" align="center">
                        <g-symbol-currency v-bind:name="record.pair.base.toLowerCase()" width="24" height="24"/>
                        <span><b>{{ record.pair.base }}</b>:{{ record.pair.counter }}</span>
                    </g-flex>
                </span>

                <span class="type cell" v-bind:class="record.position.direction.toLowerCase()" v-bind:data-field="$t('trading.type') + ': '">
                    {{ {Long: 'Buy', Short: 'Sell'}[record.position.direction] }}
                </span>

                <span class="value cell" v-bind:data-field="$t('trading.value', 'Value') + ': '">
                    {{ Number(record.position.value) }}
                </span>
                <span class="amount cell" v-bind:data-field="$t('trading.quantity', 'Quantity') + ': '">
                    {{ Number(record.position.amount) }}<b class="leverage" v-if="record.position.leverage > 1">✕{{ record.position.leverage }}</b>
                </span>

                <span class="entry-price cell" v-bind:data-field="$t('trading.entry_price', 'Entry price') + ': '">
                    <b>{{ Number(record.position.entry_price) }}</b> {{ record.pair.counter }}
                </span>
                <span class="close-price cell" v-bind:data-field="$t('trading.close_price', 'Close price') + ': '">
                    <b>{{ Number(record.close_price) }}</b> {{record.pair.counter }}
                </span>

                <span class="pl cell" v-bind:data-field="$t('trading.pl', 'PnL') + ': '" v-bind:class="record.pl > 0 ? 'increase' : 'decrease'">
                    {{ Number(record.pl).toFixed(2) }} USDT
                </span>
                <span class="swap cell" data-field="Swap: ">
                    {{ Math.round(record.position.amount * (record.position.swop || 0) * 100) / 100  }} USD
                </span>

                <span class="status cell"
                      v-bind:data-field="$t('trading.status', 'Status') + ': '"
                      v-bind:class="{ decrease: (record.status === 'Stop Loss' || record.status === 'Liquidation') }">
                    {{ record.status }}
                </span>

                <span class="opened-at cell" v-bind:data-field="$t('trading.opened_at', 'Opened at') + ': '">
                    {{ record.position.created_at }}
                </span>
                <span class="closed-at cell" v-bind:data-field="$t('trading.closed_at', 'Closed at') + ': '">
                    {{ record.created_at }}
                </span>
            </div>

            <g-pagination v-model="page" v-bind:total="Math.ceil(history.state.length / perPage)"/>

        </div>
    </div>
</template>

<style lang="scss" scoped>
.row {
    font-size: 0;
    white-space: nowrap;
    display: grid;
    align-items: center;
    padding: 6px 10px;
    color: var(--main-text-color, $main-text-color);

    & > .cell {
        font-size: 12px;

        &.currency-pair {
            grid-area: crr;

            & > b {
                color: var(--title-text-color, $title-text-color);
            }
        }

        &.type {
            grid-area: typ;
        }

        &.value {
            grid-area: val;
        }

        &.amount {
            grid-area: amt;

            & > .leverage {
                color: var(--warning, $warning);
            }
        }

        &.entry-price {
            grid-area: ent;
        }

        &.close-price {
            grid-area: prc;
        }

        &.pl {
            grid-area: prf;
        }

        &.swap {
            grid-area: swp;
        }

        &.opened-at {
            grid-area: opn;
        }

        &.closed-at {
            grid-area: cls;
        }

        &.status {
            grid-area: sts;
            text-transform: capitalize;
        }
    }
}

.titles {
    border-bottom: 1px solid var(--separator-color, $separator-color);
    margin-bottom: 0;
}

.total_pl {
    padding: 10px 20px 5px 20px;
    letter-spacing: 0.5px;
    fill: var(--main-text-color, $main-text-color);
    font-family: var(--font-title, $font-title);
}

.increase {
    color: var(--increase, $increase);

    &::after {
        transform: rotate(-90deg);
    }
}

.decrease {
    color: var(--decrease, $decrease);

    &::after {
        transform: rotate(90deg);
    }
}

.g-pagination {
    padding: 20px 10px;
}

@media (min-width: 1205px) {
    .row {
        grid-gap: 6px;
        grid-template-rows: repeat(auto-fill, 32px);
        grid-template-areas: 'crr typ val amt ent prc prf swp sts opn cls';
        grid-template-columns:
            120px
            40px
            repeat(2, minmax(min-content, 0.8fr))
            repeat(2, minmax(min-content, 1.2fr))
            130px
            70px
            90px
            repeat(2, 126px);

        & > .cell {
            &:last-child {
                text-align: center;
            }
        }
    }
}

@media (max-width: 1204px) {
    .titles {
        display: none;
    }

    .row {
        grid-template-rows: repeat(auto-fill, 1fr);
        border-bottom: 1px solid var(--separator-color, $separator-color);

        & > .cell {
            font-size: 13px;
            padding: 8px 4px;

            &::before {
                display: block;
                font-size: 12px;
                content: attr(data-field);
                color: var(--label-color, $label-color);
            }
        }
    }

    .currency-pair {
        font-size: 16px !important;

        .g-symbol-currency {
            width: 32px;
            height: 32px;
        }
    }
}

@media (min-width: 925px) AND (max-width: 1204px) {
    .row {
        padding: 15px;
        grid-template-areas:
            'crr crr crr crr crr crr opn cls'
            'typ val amt ent prc prf swp sts';
        grid-template-columns: repeat(6, minmax(min-content, 1fr)) 140px 140px;
    }
}

@media (min-width: 745px) AND (max-width: 924px) {
    .row {
        padding: 15px;
        grid-template-areas:
            'crr crr typ swp opn cls'
            'val amt ent prc prf sts';
        grid-template-columns: repeat(2, minmax(min-content, 0.8fr)) repeat(2, minmax(min-content, 1.2fr)) 140px 140px;
    }
}

@media (min-width: 505px) AND (max-width: 744px) {
    .row {
        padding: 15px;
        grid-template-areas:
            'crr crr typ sts'
            'ent val swp opn'
            'prc amt prf cls';
        grid-template-columns: 140px repeat(2, 1fr) 140px;
    }
}

@media (max-width: 504px) {
    .row {
        grid-template-areas:
            'crr crr ent'
            'typ sts prc'
            'val swp opn'
            'amt prf cls';
        grid-template-columns: 1fr 1fr 126px;
    }
}
</style>
