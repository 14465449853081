<script setup>
import { ref, defineEmits } from 'vue';
import { useDepositActions } from '@deposit';

const emit = defineEmits(['close']);

const getDepositResource = useDepositActions('getDepositResource');
const deposit = getDepositResource();

const code = ref('');

const submit = () => {
    deposit.save(null, {otp_code: code.value}).then(() => {
        emit('close');
    });
};
</script>

<template>
    <form v-on:submit.prevent="submit">
        <div class="text">{{ $t('finances.you_have_been_sent_an_otp_code') }}</div>
        <div class="field">
            <g-field v-bind:label="$t('finances.otp_code', 'OTP Code')"
                    v-model="code"
                    type="number"
                    required/>
            <g-button type="primary">{{ $t('finances.send_code', 'Send Code') }}</g-button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    width: 100%;
    min-width: 275px;
}

.field {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr;
    gap: 8px;
}

.text {
    font-size: 16px;
}

@media (min-width: 425px) {
    form {
        max-width: 472px;
    }
}

@media (min-width: 768px) {
    .field {
        grid-template-columns: repeat(2, minmax(min-content, 1fr));
    }
}
</style>
