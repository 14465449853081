import actionLoader from '@/Ship/core/ActionLoader';
// import taskLoader from '@/Ship/core/TaskLoader';
import resourceLoader from '@/Ship/core/ResourceLoader';
import componentLoader from '@/Ship/core/ComponentLoader';

//--- Actions ---//
export function useNotificationsAtions(ask = null) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);

    return ask
        ? actionLoader(requireActions)[ask]
        : actionLoader(requireActions);
}

//--- Tasks ---//
// export function useNotificationsTasks(ask = null) {
//     const requireTasks = require.context('./tasks', true, /[\w-]+\.js$/);

//     return ask
//         ? taskLoader(requireTasks)[ask]
//         : taskLoader(requireTasks);
// }

// export function useNotificationsenticationTask(ask) {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).task(ask);
// }

//--- Resources ---//
export function useNotificationsResources(ask = null) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);

    return ask
        ? resourceLoader(requireResources)[ask].getInstance()
        : resourceLoader(requireResources);
}

//--- Components ---//
export function useNotificationsComponents(ask = null) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);

    return ask
        ? componentLoader(requireComponents)[ask]
        : componentLoader(requireComponents);
}

export default require('./View').default;
