import { useShipResources } from '@/Ship';
import {
  STACK,
  ApiClient,
} from '@/Ship';
import { reactive } from 'vue';
import { useProfileActions } from '@profile';

const mock = {
    "data": [
        {
            "id": "e70510c6-b9a2-457f-a866-1bf99245c630",
            "type": "App\\Notifications\\UserNotifications\\LeadAssignedNotification",
            "data": {
                "lead_id": 21,
                "title": "You`ve been assigned to Lead #21",
                "body": "Admin Lara Croft has assigned to you Lead #21",
                "type_id": 1,
                "actor_id": 1
            },
            "read_at": null,
            "created_at": "2024-11-25T12:34:55.000000Z"
        }
    ],
    "links": {
        "first": "https://crm.tipitek.ovh/notifications/users/1?page=1",
        "last": "https://crm.tipitek.ovh/notifications/users/1?page=1",
        "prev": null,
        "next": null
    },
    "meta": {
        "current_page": 1,
        "from": 1,
        "last_page": 1,
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "https://crm.tipitek.ovh/notifications/users/1?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": null,
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "path": "https://crm.tipitek.ovh/notifications/users/1",
        "per_page": 20,
        "to": 1,
        "total": 1
    }
}

const { BaseResource } = useShipResources();
const getProfileResource = useProfileActions('getProfileResource');

export default class Notifications extends BaseResource {

    static #instance = null;

    endpoint = 'notifications';
    referer = 'Notifications Resource';
    links = reactive({});

    static getInstance() {
        if (!Notifications.#instance) {
          Notifications.#instance = new Notifications();
        }
        return Notifications.#instance;
    }

    load(params = {}, loadMore) {
        const profile = getProfileResource();
        this.loaded.value = false;
        this.loading.value = true;
        if (this.meta.showOnlyUnread) {
            params.is_unread_only = true;
        }
        if (typeof this.meta.typeId === 'number') {
            params.type_id = this.meta.typeId;
        }

        return STACK.push(() => ApiClient.get( `${this.endpoint}/users/${profile.id}`, {
            params,
            headers: {'Ref': this.referer}
        }))
            .then(response => {
                this.collection.value = loadMore ?
                    this.collection.value.concat(response.data || []) :
                    response.data || []
                this.loaded.value = true;
                this.loading.value = false;

                if (response.meta) {
                    Object.assign(this.meta, response.meta);
                }

                if (response.filters) {
                    Object.assign(this.filterset, response.filters);
                }

                if (response.links) {
                    Object.assign(this.links, response.links);
                }

                if (Array.isArray(this.onload)) {
                    this.onload.forEach(action => action(response));
                } else if (typeof this.onload === 'function') {
                    this.onload();
                }

                return response;
            })
            .finally(() => {
                this.loaded.value = true;
                this.loading.value = false;
            });
    }
}
