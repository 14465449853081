<script setup>

import { defineEmits, defineProps, watch } from 'vue';

const props = defineProps({
    tabList: {
        type: Object,
    },
    disables: {
        type: Array,
        default: [],
    },
    modelValue: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:modelValue', 'change']);
watch(() => props.modelValue, () => {
    emit('change', true);
});

</script>

<template>
    <div class="g-tabs">
        <component v-for="(value, key) in tabList"
            v-bind:is="value.to ? 'router-link' : 'button'"
            v-bind:to="value.to && value.to"
            v-bind:key="key"
            v-bind:active-class="value.to ? 'active' : undefined"
            class="tab"
            v-bind:class="[{'active': key === modelValue }, {disabled: disables.includes(key)}]"
            v-on:click="emit('update:modelValue', key)">
            <g-symbol v-if="value.icon" class="icon" v-bind:name="value.icon.name" width="24" height="24" />
            <span>{{ value.title || value }}</span>
            <g-symbol name="lock" v-if="disables.includes(key)" width="16" height="16"/>
        </component>
    </div>
</template>

<style lang="scss" scoped>
.g-tabs {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 0 10px;
    font-family: $font-title;
    height: var(--bar-heigh, $bar-height);
    background-color: var(--bar-bg, $bar-bg);

    .tab {
        padding: 0 20px;
        height: var(--tab-height, $tab-height);
        line-height: var(--tab-height, $tab-height);
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
        cursor: pointer;
        text-transform: capitalize;
        border-radius: var(--tab-rounding, $tab-rounding);

        > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:not(.active) {
            background-color: var(--tab-bg, $tab-bg);
            color: var(--tab-text-color, $tab-text-color);
        }

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }

        &.active {
            background-color: var(--tab-active-bg, $tab-active-bg);
            color: var(--tab-active-text-color, $tab-active-text-color);
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &:has(> .icon) {
            display: inline-flex;
            align-items: center;
            column-gap: 5px;
        }
    }
}

.g-symbol {
    fill: var(--disabled-color, $disabled-color);
    flex-shrink: 0;
}

.icon {
    fill: currentColor;
    flex-shrink: 0;
}
</style>