<template>
    <div class="deposit">
        <div class="form">
            <div class="card" v-bind:class="{flipped: showCardBack}">
                <div class="card-inner">
                    <div class="card-front">
                        <img src="/card/card.png" alt="">

                        <div class="card-details">
                            <div class="card-top">
                                <img src="/card/card-logo.svg" alt="">
                                <img v-if="currentCardType" v-bind:src="`/card/${currentCardType}.svg`" alt="">
                            </div>

                            <div class="card-number">{{ payload.number || '5812 2139 0823 6453' }}</div>

                            <div class="card-details-bottom">
                                <div class="card-name">
                                    <span>Cardholder name</span>
                                    <strong>{{ payload.name || 'JOHN DOE' }}</strong>
                                </div>
                                <div class="card-date">
                                    <span>VALID THRU</span>
                                    <strong>{{ payload.exp || '05/28' }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-back">
                        <img src="/card/card-back.png" alt="">

                        <div class="card-details">
                            <div class="card-details-bottom">
                                <div class="card-name">
                                    <span>Cardholder name</span>
                                    <strong>{{ payload.name || 'JOHN DOE' }}</strong>
                                </div>
                                <div class="card-date">
                                    <span>CVV</span>
                                    <strong>{{ payload.cvv || '039' }}</strong>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <form v-on:submit.prevent="submit">
                <g-field v-bind:label="$t('finances.name', 'Name')"
                    v-model="payload.name"
                    maxlength="20"
                    required
                />
                <g-field v-bind:label="$t('finances.card_number', 'Card Number')"
                    v-model="payload.number"
                    v-bind:mask="numberMask"
                    v-bind:error="errors.number"
                    v-on:input="onInput"
                    required
                >
                    <img v-if="currentCardType" v-bind:src="`/card/${currentCardType}-input.svg`" alt="">
                </g-field>
                <g-field v-bind:label="$t('finances.expiration', 'Expiration (mm/yy)')"
                    v-model="payload.exp"
                    v-bind:mask="expMask"
                    v-bind:error="errors.exp"
                    v-on:input="onInput"
                    required
                />
                <g-field v-bind:label="$t('finances.cvv', 'CVV')"
                    v-model="payload.cvv"
                    v-bind:mask="cvvMask"
                    v-bind:error="errors.cvv"
                    v-on:focus="showCardBack = true"
                    v-on:blur="showCardBack = false"
                    v-on:input="onInput"
                    required
                />
                <div class="amount">
                    <g-field v-bind:label="$t('finances.amount', 'Amount')"
                        v-model="payload.amount"
                        type="number"
                        required
                    />
                    <g-select v-model="payload.currency"
                        v-bind:options="currencies"
                        v-bind:disabled="currencies.length === 1"
                        option-value="value"
                        option-text="value" />
                </div>
                
                <g-button type="primary">{{ $t('base.continue', 'Continue') }}</g-button>
            </form>
        </div>

        <history />

        <div class="logos">
            <img src="/visa-logo.svg" alt="">
            <img src="/mastercard-logo.svg" alt="">
            <img src="/stripe-logo.svg" alt="">
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useDepositComponents, useDepositResources } from '@deposit';
import { IMask, useIMask } from 'vue-imask';
import { i18n, PopUpManager as PopUp } from '@/Ship';

const history = useDepositComponents('History');
const deposit = useDepositResources('Deposit');

const payload = reactive({
    name: '',
    number: '',
    exp: '',
    cvv: '',
    amount: '',
    currency: 'EUR',
});
const currentCardType = ref('mastercard');
const showCardBack = ref(false);
const currencies = ref([
    { value: 'EUR' },
    { value: 'USD' },
]);
const errors = reactive({
    number: '',
    exp: '',
    cvv: '',
});
const formIsTouched = ref(false);

const expMask = useIMask({
    mask: 'MM{/}YY',
    blocks: {
        MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
        },
        YY: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 99,
        },
    }
});
const numberMask = useIMask({
    mask: [
        {
            mask: '0000 000000 00000',
            regex: '^3[47]\\d{0,13}',
            cardtype: 'american-express'
        },
        {
            mask: '0000 0000 0000 0000',
            regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
            cardtype: 'discover'
        },
        {
            mask: '0000 0000 0000 0000',
            regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
            cardtype: 'mastercard'
        },
        {
            mask: '0000 0000 0000 0000',
            regex: '^4\\d{0,15}',
            cardtype: 'visa'
        },
        {
            mask: '0000 0000 0000 0000',
            cardtype: ''
        }
    ],
    dispatch(appended, dynamicMasked) {
        const number = (dynamicMasked.value + appended).replace(/\D/g, '');
        currentCardType.value = dynamicMasked.currentMask?.cardtype;

        for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
            const re = new RegExp(dynamicMasked.compiledMasks[i].regex);
            if (number.match(re) != null) {
                return dynamicMasked.compiledMasks[i];
            }
        }
    }
});

const cvvMask = useIMask({
    mask: '000',
});

const onInput = () => {
    if (formIsTouched.value) validate();
}

const setError = (key, mask = numberMask) => {
    try {
        errors[key] = !mask.mask.value.masked.isComplete ? i18n.global.t('validator.include') : '';
    } catch (error) {
        console.error(error);
    }
}

const validate = () => {
    setError('number', numberMask);
    setError('exp', expMask);
    setError('cvv', cvvMask);
}

const submit = () => {
    formIsTouched.value = true;
    validate();
    if (Object.values(errors).some(err => err)) return;
    
    deposit.create(payload).then(() => {
        PopUp.open(require('@deposit/components/PopUps/SendOtpCode'))
            .setCaption(i18n.global.t('finances.payment_confirmation'));
    });
}

</script>
<style lang="scss" scoped>
.deposit {
    display: flex;
    flex-direction: column;
    row-gap: 71px;
    width: 100%;
    max-width: 842px;
    margin-left: auto;
    margin-right: auto;
}

.form {
    gap: 40px;
    display: flex;
    flex-direction: column;

    > .card {
        position: relative;
        color: #fff;
        perspective: 1000px;
        width: 100%;
        padding-top: calc(249 / 401 * 100%);

        .card-inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: transform 0.8s;
            transform-style: preserve-3d;
        }

        .card-front,
        .card-back {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;

            > img {
                box-shadow: 0 20px 30px #0f0f1666;
                width: 100%;
            }
        }

        .card-back {
            transform: rotateY(180deg);

            .card-details-bottom {
                margin-top: auto;
            }
        }

        .card-details {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 25px 24px 20px 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .card-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .card-number {
            font-size: 29px;
            font-weight: 700;
            margin-bottom: 8px;
            width: 100%;
            margin-top: auto;
            white-space: nowrap;
        }

        .card-details-bottom {
            display: grid;
            grid-template-columns: minmax(0, 1fr) 70px;
        }

        .card-name,
        .card-date {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;

            > span {
                text-transform: uppercase;
                font-size: 12px;
            }

            > strong {
                font-size: 22px;
                font-weight: 400;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
            }
        }

        &.flipped {
            .card-inner {
                transform: rotateY(180deg);
            }
        }
    }

    > form {
        display: grid;
        gap: 8px 16px;
        grid-template-columns: 1fr 130px;
        align-items: flex-end;

        .g-field {
            &:nth-child(1),
            &:nth-child(2) {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            > .field {
                > img {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, calc(-1 * 50% - 3.5px));
                    right: 2.5rem;
                    width: calc(53 / 16 * 1rem);
                    height: calc(35 / 16 * 1rem);
                    object-fit: contain;
                }
            }
        }

        .amount {
            position: relative;

            > .g-select {
                position: absolute;
                z-index: 1;
                top: 50%;
                transform: translate(0, calc(-1 * 50% + 3.5px));
                right: 6px;
                width: calc(68 / 16 * 1rem);

                &.disabled {
                    opacity: 1;

                    :deep(.g-symbol) {
                        display: none;
                    }

                    :deep(.field) {
                        padding-right: 0;
                    }

                    :deep(input) {
                        padding: 0 !important;
                        text-align: center;
                    }
                }

                > :deep(.field) {
                    margin-top: 0;
                    border: 0;
                    background-color: #fff;
                    height: calc(35 / 16 * 1rem);
                    padding-right: 6px;

                    input {
                        color: #000;
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding-left: 8px;
                    }

                    > .arrow {
                        fill: #000;
                        width: 11px;
                        height: 11px;
                    }

                    > legend {
                        display: none;
                    }
                }
            }
        }
    }
}

.logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    max-width: 535px;
    margin-left: auto;
    margin-right: auto;

    > img {
        max-width: 145px;
    }
}

@media (min-width: 425px) {
    .form {
        > .card {
            width: 401px;
            height: 249px;
            padding-top: 0;

            .card-number {
                font-size: 35px;
            }
        }
    }

    .logos {
        flex-direction: row;
    }
}

@media (min-width: 768px) {
    .body {
        padding: 26px 38px;
    }

    .logos {
        > img {
            max-width: 100%;
        }
    }
}

@media (min-width: 992px) {
    .form {
        display: grid;
        grid-template-columns: 401px minmax(0, 1fr);
    }
}
</style>