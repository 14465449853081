<template>
    <div class="history">
        <g-caption weight="700" size="4">{{ $t('finances.last_deposit', 'Last deposit') }}</g-caption>
        <table>
            <thead>
            <tr class="titles">
                <th class="cell time">{{ $t('finances.time', 'Time') }}</th>
                <th class="cell amount">{{ $t('finances.amount', 'Amount') }}</th>
                <th class="cell currency">{{ $t('finances.currency', 'Currency') }}</th>
                <!-- <th class="cell info">{{ $t('finances.info', 'Info') }}</th> -->
                <th class="cell status">{{ $t('finances.status', 'Status') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in deposits.state" v-bind:key="row.id">
                <td class="cell date">{{ row.created_at }}</td>
                <td class="cell amount">{{ row.amount }}</td>
                <td class="cell currency">{{ row.currency }}</td>
                <!-- <td class="cell info">{{ row.info }}</td> -->
                <td class="cell status">{{ row.status }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { useDepositActions } from '@deposit';

const getDepositResource = useDepositActions('getDepositResource');
const deposits = getDepositResource();

</script>

<style lang="scss" scoped>
.history {
    overflow: auto;

    table {
        width: 100%;
        min-width: 700px;

        th,
        td {
            padding-top: 11px;
            padding-bottom: 11px;
        }

        th {
            font-size: 14px;
            color: #bbbbbb;
            text-align: left;
        }

        td {
            color: #fff;

            &.currency {
                font-weight: 700;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #ffffff0d;
            }
        }
    }
}

.g-caption {
    margin-bottom: 16px;
    padding: 0;
    color: #fff;
    font-size: 16px;
}
</style>
