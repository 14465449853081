import { ApiClient, STACK } from '@/Ship';
import { useNotificationsResources, useNotificationsAtions } from '@notifications';
const notifications = useNotificationsResources('Notifications');
const { getUnreadCount } = useNotificationsAtions();

/**
@param notification {NotificationResponseModel}
@param action {'read' | 'unread'}
*/
export default (notification, action) => {
    return STACK.push(() => {
        notifications.loaded.value = false;
        notifications.loading.value = true;

        return ApiClient.patch(`${notifications.endpoint}/${notification.id}/${action}`)
            .then(res => {
                getUnreadCount()
                    .then(() => {
                        const currNotification = notifications.state.find(n => n.id === notification.id);
                        if (currNotification) {
                            currNotification.read_at = action === 'read' ? new Date().toISOString() : null;
                            if (notifications.meta.showOnlyUnread && action === 'read') {
                                notifications.collection.value = notifications.state.filter(n => n.id !== currNotification.id);
                            }
                        }

                        notifications.loaded.value = true;
                        notifications.loading.value = false;
                    })
                    .finally(() => {
                        notifications.loaded.value = true;
                        notifications.loading.value = false;
                    });
            return res;
        });
    });
};
