// import { toast } from 'vue3-toastify';
import { useNotificationsAtions } from '@notifications';
const {
    getUnreadCount,
    // getNotificationTypeName 
} = useNotificationsAtions();

// const PushNotification = (props) => 
//     `<div class="Toastify__toast-title"></div>
//     <div class="Toastify__toast-text">
//         <p>${props.title}</p>
//     </div>`

export default (data) => {
    // toast(PushNotification(data), {
    //     dangerouslyHTMLString: true,
    //     hideProgressBar: true,
    //     autoClose: false,
    //     toastClassName: getNotificationTypeName(data.type_id),
    // });

    const audio = new Audio('/audio/mixkit-select-click-1109.wav');
    audio.play();

    getUnreadCount();
}
