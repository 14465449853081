<script setup>

import { computed, ref, onMounted, onUnmounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { STATE, WebSockets as Stream } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';
import { useChartActions } from '@chart';

const props = defineProps(['symbol', 'sortable']);

const {
    sortBy,
    getMarketResource,
    getCryptoResource,
    resolveQuotesStream,
} = useQuoteActions();

const {
    Ticker,
    Search,
} = useQuoteComponents();

const getBarResource = useChartActions('getBarResource');


const searchString = ref('');
const counterCurrency = ref('USDT');

const bar = getBarResource();

const markets = getMarketResource();
const resource = getCryptoResource();

const tickers = computed(() => {
    const clonedQuotes = _.cloneDeep(resource.state.filter(item => {

        const searchFilter = searchString.value
            ? item.base.includes(searchString.value.toUpperCase())
            : true;

        return (
            searchFilter &&
            //(item.base !== props.symbol.base || item.counter !== props.symbol.counter) &&
            item.counter === counterCurrency.value
        );
    }));

    // Sorting
    clonedQuotes.sort(
        sortBy(props.sortable || 'price')
    );

    return ref(clonedQuotes).value;
});

const router = useRouter();

function chooseQuote({ currentTarget: { dataset: {base, counter} } }) {
    bar.state = [];
    bar.loaded.value = false;

    const symbol = `${base}${counter}`;
    STATE.MARKET = 'crypto';
    STATE.SYMBOL = symbol;
    resource.choose(symbol);
    markets.choose('crypto');

    router.push({ params: {
        market: 'crypto',
        base: base.toLowerCase(),
        counter: counter.toLowerCase(),
    } });
}

/** Stream **/
const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

onMounted(() => {
    stream.subscribe({ quotes: { market: 'crypto' }});
    stream.addHandlers({
        crypto: resolveQuotesStream,
    });
});

onUnmounted(() => {
    stream.unsubscribe('quotes');
});
</script>

<template>
    <div class="toolbar">
        <g-flex class="counter-currencies" justify="around" align="center">
            <button v-on:click="counterCurrency = 'USDT'" v-bind:class="{'active-tab': counterCurrency === 'USDT'}">
                USDT
            </button>
            <button v-on:click="counterCurrency = 'BTC'" v-bind:class="{'active-tab': counterCurrency === 'BTC'}">
                BTC
            </button>
            <button v-on:click="counterCurrency = 'ETH'" v-bind:class="{'active-tab': counterCurrency === 'ETH'}">
                ETH
            </button>
        </g-flex>

        <search v-model="searchString"/>
    </div>



    <!-- Favorite Tickers -->
    <!--
    <ticker v-for="ticker in tickers.filter(item => item.isFavorite)"
            v-bind:key="ticker.symbol"
            v-bind:ticker="ticker"
            v-bind:data-symbol="ticker.symbol"
            v-bind:data-market="ticker.market"
            v-on:click="chooseQuote"/>
    -->

    <!-- Other Tickers -->
    <div class="tickers">
        <ticker v-for="ticker in tickers"
                v-bind:key="ticker.symbol"
                v-bind:ticker="ticker"
                v-bind:data-base="ticker.base"
                v-bind:data-counter="ticker.counter"
                v-bind:class="{active: (ticker.base === symbol.base && ticker.counter === symbol.counter)}"
                v-on:click="chooseQuote"/>
    </div>
</template>

<style lang="scss" scoped>
.counter-currencies {
    padding: 12px 0 4px;
    border-top: 1px solid var(--separator-color, $separator-color);

    & > button {
        font-size: 13px;
        padding: 0 8px 5px;
        background-color: transparent;

        &:not(.active-tab) {
            color: var(--main-text-color, $main-text-color);
        }

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }
    }
}

.search {
    margin: 0 8px;
}

.ticker {
    margin-bottom: 2px;
}
</style>
