import { computed } from 'vue';
import NotificationsTypeEnum from '@notifications/enums/NotificationsTypeEnum';

export default typeId => {
  return computed(() => {
    return Object.keys(NotificationsTypeEnum)
      .find(key => NotificationsTypeEnum[key] === typeId)
      ?.toLowerCase();
  });
};
