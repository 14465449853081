<script setup>
import { useNotificationsAtions, useNotificationsResources } from '@notifications';
import { useShipActions } from '@/Ship';
import { computed } from 'vue';
const { markAs, getNotificationTypeName } = useNotificationsAtions();
const { timeFromNow, formatDate } = useShipActions();

const notifications = useNotificationsResources('Notifications');

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['notificationClick']);

const type = getNotificationTypeName(props.notification.data.type_id);

const isWithdrawCreated = computed(() => {
    return !!props.notification?.data.body?.method;
})

const onMarkClick = async () => {
    props.notification.read_at ?
        await markAs(props.notification, 'unread') :
        await markAs(props.notification, 'read');
}
</script>

<template>
    <div class="notification" v-bind:class="{ 'is-unread': !notification.read_at }" v-bind:data-type="type">
        <g-preloader v-if="notifications.isLoading" />
        <div class="inner" v-on:click="$emit('notificationClick')">
            <div class="title-block">
                <p class="title"><span>{{ timeFromNow(notification.created_at) }}</span></p>
                <div class="tag">{{ $t(`base.${type}`) }}</div>
            </div>
            <div class="text">
                <strong>{{ notification.data.title }}</strong>
                <ul v-if="isWithdrawCreated">
                    <li><strong>{{ $t('base.method') }}</strong>: {{ notification.data.body.method }}</li>
                    <li><strong>{{ $t('base.amount') }}</strong>: {{ notification.data.body.amount }}</li>
                    <li><strong>{{ $t('base.address') }}</strong>: <g-copy symbol="copy-2" v-bind:text="notification.data.body.address" v-on:click.stop  /></li>
                    <li><strong>{{ $t('base.date') }}</strong>: {{ formatDate(notification.data.body.date) }}</li>
                </ul>
                <p v-else-if="typeof notification.data.body === 'string'">{{ notification.data.body }}</p>
            </div>
        </div>
        <button
            class="mark with-tooltip bottom-end"
            type="button"
            v-bind:data-tooltip="$t(`notifications.${notification.read_at ? 'mark_as_unread' : 'mark_as_read'}`)"
            v-on:click="onMarkClick"
            v-bind:disabled="notifications.isLoading"
        />
    </div>
</template>

<style lang="scss" scoped>
.notification {
    position: relative;
    border-radius: 9px;
    color: var(--notifications-color, $notifications-color);
    background-color: var(--notifications-background-color, $notifications-background-color);
    border: 1px solid var(--notifications-border-color, $notifications-border-color);
    box-shadow: var(--notifications-box-shadow, $notifications-box-shadow);

    .title-block {
        margin-bottom: 15px;
    }

    > .mark {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    &.is-unread {
        background-color: var(--notifications-background-color-unread, $notifications-background-color-unread);

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 9px;
            background-color: var(--primary, $primary);
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
        }

        > .mark {
            background-color: var(--primary, $primary);
            border-color: var(--primary, $primary);
        }
    }

    &[data-type='important'] {
        --tag-background-color: var(--notifications-type-color-important, $notifications-type-color-important);
    }

    &[data-type='main'] {
        --tag-background-color: var(--notifications-type-color-main, $notifications-type-color-main);
    }

    &[data-type='info'] {
        --tag-background-color: var(--notifications-type-color-info, $notifications-type-color-info);
    }
}

.inner {
    cursor: pointer;
    padding: 18px 20px 16px;
}

.mark {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--notifications-mark-toggle-background-color, $notifications-mark-toggle-background-color);
    border: 1px solid var(--notifications-mark-toggle-border-color, $notifications-mark-toggle-border-color);

    &:hover {
        &::before {
            border-bottom-color: var(--tooltip-bg, $tooltip-bg);
        }

        &::after {
            white-space: nowrap;
        }
    }
}

.title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 16px;
    font-size: 12px;
}

.text {
    > strong {
        display: block;
        margin-bottom: 8px;
        color: var(--title-text-color, $title-text-color);
    }

    > p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    > ul {
        list-style-type: none;
    }
}

.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 66px;
    padding: 3px 12px 4px;
    font-size: 12px;
    border-radius: 10px;
    background-color:
        color-mix(
            in srgb,
            var(--tag-background-color, var(--notifications-type-color-main, $notifications-type-color-main)) 30%,
            transparent
        );
}
</style>