<template>
    <div class="ticker">
        <!--
        <g-symbol name="favorite"
                  width="16"
                  height="16"
                  v-on:click.stop="changeFavorite(ticker)"/>
        -->
        <!--
        <div class="symbol">
            <span class="base-currency">{{ ticker.baseAsset }} / </span>
            <span class="counter-currency">{{ ticker.counterAsset }}</span>
        </div>

        <span class="price">{{ ticker.price ?? 0 }}</span>
        <span class="volume">{{ ticker.volume ?? 0 }}</span>
        -->

        <!-- // -->
        <g-flex justify="between" align="center">
            <g-flex class="ticker-info" gap="10" align="center">
                <g-symbol-currency v-bind:name="ticker.base.toLowerCase()" width="26" height="30"/>
                <span class="name"><b class="base-currency">{{ ticker.base }}</b>:{{ ticker.counter }}</span>
            </g-flex>
            <b class="price" v-bind:class="priceScale ? 'increase' : 'decrease'">{{ ticker.price ?? 'n/a' }}</b>
            <span class="percent" v-bind:change="change24h = calcDifference(ticker.price, ticker.open)" v-bind:class="change24h < 0 ? 'decrease' : 'increase'">
                <b v-if="Math.abs(change24h) >= 0.01">{{ change24h.toFixed(2) }}%</b>
                <span v-else><span style="font-size: 12px">> </span><b>0.01%</b></span>
            </span>
        </g-flex>
    </div>
</template>

<script>

import { ref, watch } from 'vue';

export default {
    name: 'Ticker',
    props: ['ticker'],

    setup(props) {
        let change24h = 0;
        function calcDifference(newPrice, oldPrice) {
            return (newPrice - oldPrice) * 100 / oldPrice;
        }

        const priceScale = ref(false);

        watch(() => props.ticker.price, () => {
            priceScale.value = !priceScale.value;
        });

        return {
            change24h,
            priceScale,
            calcDifference
        };
    }
};
</script>

<style lang="scss" scoped>
.ticker {
    cursor: pointer;
    margin: 1px 0;
    font-size: 1rem;
    padding: 12px 12px;
    border: 1px solid transparent;
    border-width: 1px 0 1px 0;
    color: var(--main-text-color, $main-text-color);

    .base-currency {
        color: var(--title-text-color, $title-text-color);
    }

    &:hover {
        background-color: var(--ticker-hover, $ticker-hover);
    }
}

.percent::after {
    content: '⮕';
    width: 16px;
    height: 16px;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    transition: transform 0.2s;
    font-weight: bold;
}

.increase {
    b {
        color: var(--increase, $increase);
    }

    &::after {
        transform: rotate(-45deg);
        color: var(--increase, $increase);
    }
}

.decrease {
    b {
        color: var(--decrease, $decrease);
    }

    &::after {
        transform: rotate(45deg);
        color: var(--decrease, $decrease);
    }
}

.price {
    &.increase {
        animation: scale-increase 0.5s;
    }

    &.decrease {
        animation: scale-decrease 0.5s;
    }
}

.active {
    pointer-events: none;
    border-color: var(--success-hover, $success-hover);
}

@keyframes scale-increase {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scale-decrease {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

</style>