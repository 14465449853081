
import { ApiClient, STACK } from '@/Ship';

import { useNotificationsResources } from '@notifications';
import { useProfileActions } from '@profile';

const notifications = useNotificationsResources('Notifications');
const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

export default () => {
    return STACK.push(() => {
        return ApiClient
            .get(`${notifications.endpoint}/users/${profile.id}/unread/count`)
            .then((res) => {
                notifications.meta.unreadCount = res.data.count ?? res.data.data?.count;
                return res;
            });
    });
}
