<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import NotificationsTypeEnum from '@notifications/enums/NotificationsTypeEnum';

import { useNotificationsComponents } from '@notifications';
const { Notification } = useNotificationsComponents();

import { useNotificationsResources, useNotificationsAtions } from '@notifications';
const notifications = useNotificationsResources('Notifications');
const { getUnreadCount } = useNotificationsAtions();

const show = ref(undefined);
const observer = ref(null);
const loadMoreRef = ref();
const bodyRef = ref();

const allowLoadMore = computed(() => notifications.links.next);
const hasUnread = computed(() => notifications.meta.unreadCount > 0);
const showOnlyUnread = computed({
    get: () => notifications.meta.showOnlyUnread,
    set: value => {
        const meta = notifications.meta;
        meta.showOnlyUnread = value;
        notifications.meta = meta;

        return true;
    }
});

const loadMore = () => {
    notifications.load({ page: notifications.meta.current_page + 1 }, true);
}

const hideList = () => {
    show.value = false;
    setTimeout(() => {
        show.value = undefined;
    });
}

const onShowList = () => {
    bodyRef.value.scrollTop = 0;
    notifications.load();
}

const onShowOnlyUnreadUpdate = () => {
    notifications.load();
}

const filterNotifications = (typeId) => {
    notifications.meta.typeId = typeId;
    notifications.load();
}

const onDocClick = (e) => {
    if (!e.target.closest('.notifications')) hideList();
}

const onIntersection = (entries) => {
    if (!notifications.state.length || !allowLoadMore.value) return;

    entries.forEach(entry => {
        if (entry.isIntersecting) loadMore();
    })
}

onMounted(() => {
    getUnreadCount();
    observer.value = new IntersectionObserver(onIntersection);
    observer.value.observe(loadMoreRef.value);
    document.addEventListener('click', onDocClick);
});

onBeforeUnmount(() => {
    if (loadMoreRef.value && observer.value) observer.value.unobserve(loadMoreRef.value);
    document.removeEventListener('click', onDocClick);
});
</script>

<template>
    <g-fade-container 
        class="notifications"
        v-bind:class="{ 'has-unread': hasUnread }"
        icon="bell"
        v-bind:show="show"
        v-bind:toggleOnClick="true"
        v-bind:showArrow="false"
        v-bind:label="notifications.meta.unreadCount"
        v-on:show="onShowList"
    >
        <div class="notifications-panel">
            <div class="top">
                <div class="title-block">
                    <p class="title">{{ $t('notifications.notifications') }}</p>

                    <label class="switch-container">
                        <span>{{ $t('notifications.only_show_unread') }}</span>
                        <g-switch
                            v-bind:showIcons="true"
                            v-model:checked="showOnlyUnread"
                            v-on:update:checked="onShowOnlyUnreadUpdate"
                            v-bind:disabled="notifications.isLoading"
                        />
                    </label>
                </div>
                <div class="filters">
                    <span>{{ $t('base.sort_by') }}:</span>
                    <g-flex v-bind:align="center" v-bind:gap="5">
                        <button
                            type="button"
                            class="filter-btn"
                            data-type="all"
                            v-bind:class="{ 'is-active': typeof notifications.meta.typeId !== 'number' }"
                            v-on:click="filterNotifications()"
                            v-bind:disabled="notifications.isLoading"
                        >{{ $t('base.all') }}</button>
                        <button
                            type="button"
                            class="filter-btn"
                            data-type="important"
                            v-bind:class="{ 'is-active': notifications.meta.typeId === NotificationsTypeEnum.IMPORTANT }"
                            v-on:click="filterNotifications(NotificationsTypeEnum.IMPORTANT)"
                            v-bind:disabled="notifications.isLoading"
                        >{{ $t('base.important') }}</button>
                        <button
                            type="button"
                            class="filter-btn"
                            data-type="main"
                            v-bind:class="{ 'is-active': notifications.meta.typeId === NotificationsTypeEnum.MAIN }"
                            v-on:click="filterNotifications(NotificationsTypeEnum.MAIN)"
                            v-bind:disabled="notifications.isLoading"
                        >{{ $t('base.main') }}</button>
                        <button
                            type="button"
                            class="filter-btn"
                            data-type="info"
                            v-bind:class="{ 'is-active': notifications.meta.typeId === NotificationsTypeEnum.INFO }"
                            v-on:click="filterNotifications(NotificationsTypeEnum.INFO)"
                            v-bind:disabled="notifications.isLoading"
                        >{{ $t('base.info') }}</button>
                    </g-flex>
                </div>
            </div>

            <div class="body" ref="bodyRef">
                <g-preloader v-if="notifications.isLoading" />
                <div v-if="!notifications.state.length" class="no-data">
                    {{ $t(`notifications.${showOnlyUnread ? 'no_unread_notifications' : 'no_notifications'}`) }}
                </div>
                <notification
                    v-else
                    v-for="notification in notifications.state"
                    v-bind:key="notification.id"
                    v-bind:notification="notification"
                />
                <div ref="loadMoreRef" />
            </div>
        </div>
    </g-fade-container>
</template>

<style lang="scss" scoped>
.notifications {
    &:deep > .label > .value {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        right: 8px;
        padding: 0;
        width: 15px;
        height: 15px;
        font-size: 11px;
        background-color: var(--notifications-badge-background-color, $notifications-badge-background-color) !important;
        color: var(--notifications-badge-color, $notifications-badge-color) !important;
    }

    &:deep > .label > .icon {
        padding: 6px;
    }

    &.active:deep > .container {
        overflow: initial;
    }
}

.notifications-panel {
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    background-color: var(--notifications-panel-background-color, $notifications-panel-background-color);
    box-shadow: var(--notifications-panel-box-shadow, $notifications-panel-box-shadow);
    border: 1px solid var(--notifications-border-color, $notifications-border-color);
    min-width: 450px;
    max-height: calc(100vh - 100px);
    backdrop-filter: blur(50px);

    > .top {
        padding: 22px 20px 0;
        background-color:
            var(
                --notifications-panel-header-background-color,
                $notifications-panel-header-background-color
            );
    }

    > .body {
        position: relative;
        flex-grow: 1;
        overflow: auto;
        padding: 13px 20px 10px 20px;
        min-height: 125px;

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--notifications-scrollbar-track-color, $notifications-scrollbar-track-color);
            border-radius: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--notifications-scrollbar-thumb-color, $notifications-scrollbar-thumb-color);
            border-radius: 9px;
            border: 2px solid transparent;
            background-clip: content-box;
        }
    }
}

.title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--notifications-border-color, $notifications-border-color);
    margin-bottom: 11px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: var(--title-text-color, $title-text-color);
}

.switch-container {
    display: flex;
    align-items: center;
    column-gap: 14px;
    font-size: 12px;
    color: var(--title-text-color, $title-text-color);

    > .g-switch {
        --switch-width: 32px;
        --switch-height: 16px;
        --switch-thumb: 14px;
        --switch-circle: 14px;
        --switch-bg: var(--main-text-color, $main-text-color);
        --switch-active-bg: var(--primary, $primary);
        --switch-thumb-bg: var(--env-bg, $env-bg);
        --switch-thumb-active-bg: var(--main-text-color, $main-text-color);
    }
}

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    margin-bottom: 12px;
    color: var(--title-text-color, $title-text-color);
}

.filter-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    color: var(--notifications-filter-button-color, $notifications-filter-button-color);
    background-color:
        var(
            --notifications-filter-button-background-color,
            $notifications-filter-button-background-color
        );
    border: 1px solid var(--notifications-filter-button-border-color, $notifications-filter-button-border-color);
    border-radius: 9px;
    font-size: 12px;
    padding: 6px 16px 8px;

    &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--filter-btn-color, var(--notifications-type-color-all, $notifications-type-color-all));
    }

    &.is-active {
        color: var(--notifications-filter-button-color-active, $notifications-filter-button-color-active);
        background-color: var(--primary, $primary);
    }

    &[data-type='all'] {
        --filter-btn-color: var(--notifications-type-color-all, $notifications-type-color-all);
    }

    &[data-type='important'] {
        --filter-btn-color: var(--notifications-type-color-important, $notifications-type-color-important);
    }

    &[data-type='main'] {
        --filter-btn-color: var(--notifications-type-color-main, $notifications-type-color-main);
    }

    &[data-type='info'] {
        --filter-btn-color: var(--notifications-type-color-info, $notifications-type-color-info);
    }
}

.body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    > .no-data {
        margin: auto;
    }

    > div {
        &:last-child {
            min-height: 1px;
        }
    }
}
</style>
